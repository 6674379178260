// Set mobile viewport height
const setVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
setVH();
window.addEventListener('resize', setVH);

// Content manipulation functions
const observeSelector = (selector, callback, options = {
  timeout: null,
  document: window.document,
}) => {
  let processed = new Map();

  let obs;
  let isDone = false;

  const done = () => {
    if (obs) obs.disconnect();
    processed = undefined;
    obs = undefined;
    return (isDone = true);
  };

  const processElement = (el) => {
    if (processed && !processed.has(el)) {
      processed.set(el, true);
      callback(el);
      if (options.once) {
        done();
        return true;
      }
    }
  };

  const lookForSelector = (el = document) => {
    if (el.matches && el.matches(selector)) {
      if (processElement(el)) return true;
    }
    if (el.querySelectorAll) {
      const elements = el.querySelectorAll(selector);
      if (elements.length) {
        for (let i = 0; i < elements.length; i++) {
          const el = elements[i];
          if (processElement(el)) return true;
        }
      }
    }
  };
  lookForSelector();

  // We might finish before the mutation observer is necessary:
  if (!isDone) {
    obs = new MutationObserver((mutationsList) => {
      mutationsList.forEach((record) => {
        if (record && record.addedNodes && record.addedNodes.length) {
          for (let i = 0; i < record.addedNodes.length; i++) {
            // Need to check from the parent element since sibling selectors can be thrown off if elements show up in non-sequential order
            const el = record.addedNodes[i].parentElement;
            // if (!el) console.warn('observeSelector element has no parent', record.addedNodes[i], record);
            // Note: This appears to also run when elements are removed from the DOM. If the element doesn't have a parent then we don't need to check it.
            if (el && lookForSelector(el)) return true;
          }
        }
      });
    });
    obs.observe(options.document || document, {
      attributes: false,
      childList: true,
      subtree: true,
    });
  }

  return () => {
    done();
  };
};

const waitForElement = (selector) => new Promise((resolve) => {
  observeSelector(selector, resolve, {
    once: true
  });
});

const waitUntil = (test) => new Promise((resolve) => {
  (function poll() {
    if (!test()) return window.requestAnimationFrame(poll);
    return resolve();
  })();
});

const createEl = (
  tagType,
  className = '',
  id = null,
  innerHTML = '',
  attributes = {},
) => {
  const el = document.createElement(tagType);
  el.className = className;
  if (id !== null) el.setAttribute('id', id);
  el.innerHTML = innerHTML;
  for (const key of Object.keys(attributes)) {
    el.setAttribute(key, attributes[key]);
  };
  return el;
};

const debounce = (fn, wait, leading = false) => {
  let timeout;

  return (...args) => {
    function later() {
      timeout = null;
      if (!leading) fn(...args);
    }

    const callNow = leading && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) fn(...args);
  };
};

const getRootDomain = (hostname) => {
  let parts = hostname.split('.');
  if (parts.length <= 2) return hostname;

  parts = parts.slice(-3);
  if (['co', 'com'].includes(parts[1])) return parts.join('.');
  return parts.slice(-2).join('.');
}

const cookie = {
  set: (name, value, optDays, domain = `.${window.location.hostname.split('.').slice(-2).join('.')}`) => {
    let cookie = `${name}=${value}; path=/`;
    let date;
    if (optDays instanceof Date) {
      date = optDays;
    } else if (optDays > 0) {
      date = new Date();
      date.setTime(date.getTime() + (optDays * 24 * 60 * 60 * 1000));
    }
    if (date) {
      cookie += `; expires=${date.toGMTString()}`;
    }
    if (domain) {
      cookie += `; domain=${domain}`;
    }
    document.cookie = cookie;
  },
  get: (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  del: (name, domain = `.${window.location.hostname.split('.').slice(-2).join('.')}`) => {
    let cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    if (domain) {
      cookie += `; domain=${domain}`;
    }
    document.cookie = cookie;
  },
  exists: (param, value) => new RegExp(`(^|; )${param}${value !== undefined ? `=${value}` : '(=.*)?'}(;|$)`).test(document.cookie)
};

const currentYear = new Date().getFullYear();

window.utils = {
  observeSelector,
  waitForElement,
  waitUntil,
  createEl,
  debounce,
  cookie,
  currentYear
};

window.utilsReady = true;
window.dispatchEvent(new Event('utilsReady'));